import "./style.css";

// news-channels-imgs
import Guardian from "../../../assets/img/SectionOne/As-Seen-On-Guardian.png";
// import TC from "../../../assets/img/SectionOne/As-Seen-On-TC.png";
// import CNBC from "../../../assets/img/SectionOne/As-Seen-On-CNBC.png";
// import Arise from "../../../assets/img/SectionOne/As-Seen-On-Arise.png";
import businessDay from "../../../assets/img/SectionOne/Businessday-logo.png";
import leadership from "../../../assets/img/SectionOne/leadership-logo.png";
import dailyTrust from "../../../assets/img/SectionOne/daily-trust-logo.png";

// steps-imgs
import StepOne from "../../../assets/img/SectionOne/STEP-1.jpg";
import StepTwo from "../../../assets/img/SectionOne/STEP-2.jpg";
import StepThree from "../../../assets/img/SectionOne/STEP-3.jpg";
import StepFour from "../../../assets/img/SectionOne/STEP-4.jpg";

const SectionOne = () => {
  return (
    <>
      <div className="section-one-wrapper">
        <div className="tele-media-wrapper">
          <div className="tele-media-section">
            <h2>as seen on</h2>
            <div className="tele--media-grid">
              <div className="tele-media-grid-item">
                <a
                  href="https://guardian.ng/business-services/firm-unveils-carbon-offset-mobile-app-to-tackle-climate-change/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Guardian} alt="guardian logo" />
                </a>
              </div>
              <div className="tele-media-grid-item">
                <a
                  href="https://leadership.ng/firm-to-combat-climate-change-with-carbon-offset-mobile-app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={leadership} alt="leadership logo" />
                </a>
              </div>
              <div className="tele-media-grid-item">
                <a
                  href="https://businessday.ng/companies/article/eden-power-to-tackle-climate-change-with-launch-of-carbon-offset-mobile-app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={businessDay} alt="business day logo" />
                </a>
              </div>
              <div className="tele-media-grid-item">
                <a
                  href="https://dailytrust.com/eden-power-to-tackle-climate-change-with-carbon-offset-mobile-app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={dailyTrust} alt="daily trust logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="strategy-offset-wrapper">
          <div className="strategy-offset-section">
            <h2>
              Create your own carbon <br /> offset strategy
            </h2>
            <div className="strategy-offset-grid">
              <div className="strategy-offset-grid-item">
                <span className="offset-strategy-count">1.</span>
                <img src={StepOne} alt="" />
                <p className="offset-strategy-description">
                  calculate your <span>carbon footprint</span>
                </p>
              </div>
              <div className="strategy-offset-grid-item">
                <span className="offset-strategy-count">2.</span>
                <img src={StepTwo} alt="" />
                <p className="offset-strategy-description">
                  create your personal <span>carbon offset strategy</span>
                </p>
              </div>
              <div className="strategy-offset-grid-item">
                <span className="offset-strategy-count">3.</span>
                <img src={StepThree} alt="" />
                <p className="offset-strategy-description">
                  fund projects <span>to offset your footprint</span>
                </p>
              </div>
              <div className="strategy-offset-grid-item">
                <span className="offset-strategy-count">4.</span>
                <img src={StepFour} alt="" />
                <p className="offset-strategy-description">
                  track your progress
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionOne;
