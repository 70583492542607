import WhiteStoreDownloadBtns from "../Shared/WhiteStoreDownloadBtns/WhiteStoreDownloadBtns";
import "./styles.css";

import mockup from "../../assets/img/Headersection/mockup-edit.png";

const HeroComp = () => {
  return (
    <div className="hero-section">
      <div className="hero-content">
        <h1>take control of your carbon footprint</h1>
        <p>
          There has never been a more pressing time to take accountability for{" "}
          <br />
          your carbon emmissions even at the micro level. Make a change now!
        </p>
        <WhiteStoreDownloadBtns />
        <div className="mockup-img">
          <img src={mockup} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HeroComp;
