import "./styles.css";

const WhiteAppleStoreIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1563 618"
      style={{ enableBackground: "new 0 0 1563 618" }}
      xmlSpace="preserve"
      // fill="#fffff"
    >
      {/* <style type="text/css">
	.st0{fill:#FFFFFF;}
</style> */}
      <g>
        <g>
          <path
            className="st0"
            d="M1455.64,521.68H110.36c-31.12,0-56.36-25.23-56.36-56.36V134.65c0-31.12,25.23-56.36,56.36-56.36h1345.28
			c31.12,0,56.36,25.23,56.36,56.36v330.67C1512,496.45,1486.77,521.68,1455.64,521.68z"
          />
          <path
            d="M1455.64,525.68H110.36c-33.28,0-60.36-27.08-60.36-60.36V134.65c0-33.28,27.08-60.36,60.36-60.36h1345.28
			c33.28,0,60.36,27.08,60.36,60.36v330.67C1516,498.61,1488.92,525.68,1455.64,525.68z M110.36,82.29
			C81.49,82.29,58,105.78,58,134.65v330.67c0,28.87,23.49,52.36,52.36,52.36h1345.28c28.87,0,52.36-23.49,52.36-52.36V134.65
			c0-28.87-23.49-52.36-52.36-52.36H110.36z"
          />
        </g>
      </g>
      <g id="XMLID_27_">
        <g id="XMLID_37_">
          <path
            d="M186.52,259.46c-23.65,41.04-8.62,103.33,17.54,141.09c13.06,18.88,26.27,35.83,44.38,35.83c0.34,0,0.68-0.01,1.03-0.02
			c8.51-0.34,14.65-2.96,20.6-5.49c6.67-2.84,13.58-5.78,24.4-5.78c10.3,0,16.87,2.84,23.22,5.59c6.27,2.71,12.72,5.52,22.25,5.34
			c20.39-0.38,32.91-18.67,43.96-34.8c11.53-16.85,17.31-33.2,19.26-39.45l0.08-0.25c0.37-1.11-0.16-2.32-1.22-2.81
			c0,0-0.14-0.06-0.17-0.07c-3.59-1.47-35.09-15.44-35.42-53.53c-0.31-30.95,23.63-47.33,28.43-50.3l0.22-0.14
			c0.52-0.33,0.88-0.86,1.01-1.47c0.12-0.61,0-1.24-0.36-1.75c-16.52-24.18-41.85-27.82-52.05-28.26c-1.48-0.15-3-0.22-4.54-0.22
			c-11.98,0-23.45,4.52-32.67,8.16c-6.36,2.51-11.86,4.68-15.64,4.68c-4.26,0-9.79-2.19-16.18-4.73c-8.56-3.4-18.26-7.25-28.53-7.25
			c-0.25,0-0.49,0-0.72,0.01C221.51,224.17,198.95,237.83,186.52,259.46z"
          />
        </g>
        <g id="XMLID_34_">
          <path
            d="M343.7,156.62c-14.46,0.59-31.8,9.49-42.17,21.63c-8.81,10.2-17.42,27.22-15.15,44.37c0.14,1.07,1.02,1.9,2.1,1.99
			c0.98,0.07,1.97,0.11,2.96,0.11c14.14,0,29.39-7.82,39.81-20.42c10.96-13.3,16.51-30.36,14.83-45.65
			C345.94,157.46,344.89,156.57,343.7,156.62z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M510.07,155.44h27.42c7.19,0,13.58,1.48,19.15,4.45c5.58,2.97,9.91,7.08,13.02,12.32c3.1,5.25,4.65,11.2,4.65,17.87
			c0,6.67-1.55,12.62-4.65,17.87c-3.1,5.25-7.44,9.35-13.02,12.32c-5.58,2.97-11.96,4.45-19.15,4.45h-27.42V155.44z M537.09,220.18
			c6.4,0,12.04-1.27,16.93-3.81c4.88-2.54,8.64-6.09,11.28-10.64c2.64-4.55,3.96-9.77,3.96-15.64c0-5.87-1.32-11.09-3.96-15.64
			c-2.64-4.55-6.4-8.1-11.28-10.64c-4.88-2.54-10.53-3.81-16.93-3.81h-21.97v60.18H537.09z"
          />
          <path
            d="M597.22,221.76c-3.93-2.24-7.01-5.36-9.25-9.35c-2.24-3.99-3.37-8.5-3.37-13.51c0-5.01,1.12-9.52,3.37-13.51
			c2.24-3.99,5.33-7.11,9.25-9.35c3.93-2.24,8.33-3.37,13.21-3.37c4.88,0,9.29,1.12,13.21,3.37c3.93,2.24,7.01,5.36,9.25,9.35
			c2.24,3.99,3.37,8.5,3.37,13.51c0,5.02-1.12,9.52-3.37,13.51c-2.24,3.99-5.33,7.11-9.25,9.35c-3.93,2.24-8.33,3.37-13.21,3.37
			C605.55,225.13,601.15,224,597.22,221.76z M621.08,217.9c3.13-1.85,5.61-4.42,7.42-7.72c1.81-3.3,2.72-7.06,2.72-11.28
			c0-4.22-0.91-7.98-2.72-11.28c-1.82-3.3-4.29-5.87-7.42-7.72c-3.13-1.85-6.68-2.77-10.64-2.77c-3.96,0-7.51,0.92-10.64,2.77
			c-3.13,1.85-5.61,4.42-7.42,7.72c-1.82,3.3-2.72,7.06-2.72,11.28c0,4.22,0.91,7.99,2.72,11.28c1.81,3.3,4.29,5.87,7.42,7.72
			c3.13,1.85,6.68,2.77,10.64,2.77C614.39,220.67,617.94,219.75,621.08,217.9z"
          />
          <path
            d="M723.97,173.06l-19.6,51.67h-4.65l-17.42-44.84l-17.52,44.84h-4.55l-19.6-51.67h4.75l17.22,46.13l17.62-46.13h4.26
			l17.52,46.03l17.42-46.03H723.97z"
          />
          <path
            d="M776.03,178.31c3.76,3.76,5.64,9.17,5.64,16.23v30.19h-4.95v-29.79c0-5.81-1.47-10.23-4.4-13.26
			c-2.94-3.03-7.04-4.55-12.32-4.55c-6.07,0-10.86,1.82-14.35,5.44c-3.5,3.63-5.25,8.51-5.25,14.65v27.52h-4.95v-51.67h4.75v11.19
			c1.78-3.63,4.44-6.47,7.97-8.51c3.53-2.04,7.7-3.07,12.52-3.07C767.16,172.66,772.27,174.54,776.03,178.31z"
          />
          <path d="M802.07,151.28h4.95v73.45h-4.95V151.28z" />
          <path
            d="M834.88,221.76c-3.93-2.24-7.01-5.36-9.26-9.35c-2.24-3.99-3.37-8.5-3.37-13.51c0-5.01,1.12-9.52,3.37-13.51
			c2.24-3.99,5.33-7.11,9.26-9.35c3.93-2.24,8.33-3.37,13.21-3.37c4.88,0,9.29,1.12,13.21,3.37c3.93,2.24,7.01,5.36,9.26,9.35
			c2.24,3.99,3.37,8.5,3.37,13.51c0,5.02-1.12,9.52-3.37,13.51c-2.24,3.99-5.33,7.11-9.26,9.35c-3.93,2.24-8.33,3.37-13.21,3.37
			C843.21,225.13,838.8,224,834.88,221.76z M858.73,217.9c3.13-1.85,5.61-4.42,7.42-7.72c1.81-3.3,2.72-7.06,2.72-11.28
			c0-4.22-0.91-7.98-2.72-11.28c-1.82-3.3-4.29-5.87-7.42-7.72c-3.14-1.85-6.68-2.77-10.64-2.77s-7.51,0.92-10.64,2.77
			c-3.13,1.85-5.61,4.42-7.42,7.72c-1.82,3.3-2.72,7.06-2.72,11.28c0,4.22,0.91,7.99,2.72,11.28c1.81,3.3,4.29,5.87,7.42,7.72
			c3.13,1.85,6.68,2.77,10.64,2.77S855.6,219.75,858.73,217.9z"
          />
          <path
            d="M921.24,177.56c3.43,3.27,5.15,8.07,5.15,14.4v32.76h-4.75v-9.21c-1.65,3.04-4.06,5.39-7.23,7.08
			c-3.17,1.68-6.96,2.52-11.38,2.52c-5.74,0-10.28-1.35-13.61-4.06c-3.33-2.7-5-6.27-5-10.69c0-4.29,1.53-7.77,4.6-10.44
			c3.07-2.67,7.97-4.01,14.7-4.01h17.72v-4.16c0-4.82-1.3-8.48-3.91-10.99c-2.61-2.51-6.42-3.76-11.43-3.76
			c-3.43,0-6.72,0.59-9.85,1.78c-3.13,1.19-5.79,2.77-7.97,4.75l-2.47-3.56c2.57-2.31,5.67-4.11,9.3-5.39
			c3.63-1.29,7.42-1.93,11.38-1.93C912.89,172.66,917.81,174.3,921.24,177.56z M914.66,218.1c3-1.98,5.26-4.85,6.78-8.61v-9.7
			h-17.62c-5.08,0-8.76,0.92-11.04,2.77c-2.28,1.85-3.41,4.39-3.41,7.62c0,3.37,1.25,6.02,3.76,7.97c2.51,1.95,6.04,2.92,10.59,2.92
			C908.01,221.07,911.65,220.08,914.66,218.1z"
          />
          <path
            d="M992.8,151.28v73.45h-4.85v-12.18c-2.05,4.03-4.92,7.13-8.61,9.3c-3.7,2.18-7.89,3.27-12.57,3.27
			c-4.82,0-9.17-1.12-13.07-3.37c-3.89-2.24-6.95-5.35-9.16-9.3c-2.21-3.96-3.32-8.48-3.32-13.56c0-5.08,1.1-9.62,3.32-13.61
			c2.21-3.99,5.26-7.09,9.16-9.3c3.89-2.21,8.25-3.32,13.07-3.32c4.62,0,8.76,1.09,12.42,3.27c3.66,2.18,6.55,5.21,8.66,9.11v-33.75
			H992.8z M977.71,217.9c3.13-1.85,5.61-4.42,7.42-7.72c1.81-3.3,2.72-7.06,2.72-11.28c0-4.22-0.91-7.98-2.72-11.28
			c-1.82-3.3-4.29-5.87-7.42-7.72c-3.14-1.85-6.68-2.77-10.64-2.77s-7.51,0.92-10.64,2.77c-3.13,1.85-5.61,4.42-7.42,7.72
			c-1.82,3.3-2.72,7.06-2.72,11.28c0,4.22,0.91,7.99,2.72,11.28c1.81,3.3,4.29,5.87,7.42,7.72c3.13,1.85,6.68,2.77,10.64,2.77
			S974.57,219.75,977.71,217.9z"
          />
          <path
            d="M1046.1,221.76c-3.93-2.24-7.01-5.36-9.25-9.35c-2.24-3.99-3.37-8.5-3.37-13.51c0-5.01,1.12-9.52,3.37-13.51
			c2.24-3.99,5.33-7.11,9.25-9.35c3.93-2.24,8.33-3.37,13.21-3.37c4.88,0,9.29,1.12,13.21,3.37c3.93,2.24,7.01,5.36,9.26,9.35
			c2.24,3.99,3.36,8.5,3.36,13.51c0,5.02-1.12,9.52-3.36,13.51c-2.24,3.99-5.33,7.11-9.26,9.35c-3.93,2.24-8.33,3.37-13.21,3.37
			C1054.43,225.13,1050.03,224,1046.1,221.76z M1069.96,217.9c3.13-1.85,5.61-4.42,7.42-7.72c1.81-3.3,2.72-7.06,2.72-11.28
			c0-4.22-0.91-7.98-2.72-11.28c-1.82-3.3-4.29-5.87-7.42-7.72c-3.14-1.85-6.68-2.77-10.64-2.77c-3.96,0-7.51,0.92-10.64,2.77
			c-3.14,1.85-5.61,4.42-7.42,7.72c-1.82,3.3-2.72,7.06-2.72,11.28c0,4.22,0.91,7.99,2.72,11.28c1.81,3.3,4.29,5.87,7.42,7.72
			c3.13,1.85,6.68,2.77,10.64,2.77C1063.28,220.67,1066.82,219.75,1069.96,217.9z"
          />
          <path
            d="M1141.08,178.31c3.76,3.76,5.64,9.17,5.64,16.23v30.19h-4.95v-29.79c0-5.81-1.47-10.23-4.4-13.26
			c-2.94-3.03-7.04-4.55-12.32-4.55c-6.07,0-10.86,1.82-14.35,5.44c-3.5,3.63-5.25,8.51-5.25,14.65v27.52h-4.95v-51.67h4.75v11.19
			c1.78-3.63,4.44-6.47,7.97-8.51c3.53-2.04,7.7-3.07,12.52-3.07C1132.2,172.66,1137.32,174.54,1141.08,178.31z"
          />
          <path
            d="M1218.18,221.46c-1.25,1.19-2.82,2.1-4.7,2.72c-1.88,0.63-3.84,0.94-5.89,0.94c-4.49,0-7.95-1.24-10.39-3.71
			c-2.44-2.47-3.66-5.92-3.66-10.34v-33.75h-9.5v-4.26h9.5v-11.28h4.95v11.28h16.43v4.26h-16.43v33.26c0,3.37,0.81,5.92,2.42,7.67
			c1.62,1.75,4.01,2.62,7.18,2.62c3.23,0,5.91-0.96,8.02-2.87L1218.18,221.46z"
          />
          <path
            d="M1272.53,178.31c3.76,3.76,5.64,9.17,5.64,16.23v30.19h-4.95v-29.79c0-5.81-1.47-10.23-4.4-13.26
			c-2.94-3.03-7.04-4.55-12.32-4.55c-6.07,0-10.86,1.82-14.35,5.44c-3.5,3.63-5.25,8.51-5.25,14.65v27.52h-4.95v-73.45h4.95v32.57
			c1.78-3.56,4.42-6.32,7.92-8.27c3.5-1.95,7.62-2.92,12.37-2.92C1263.65,172.66,1268.77,174.54,1272.53,178.31z"
          />
          <path
            d="M1342.31,200.08h-44.44c0.13,4.03,1.15,7.61,3.07,10.74c1.91,3.13,4.5,5.56,7.77,7.28c3.27,1.72,6.94,2.57,11.04,2.57
			c3.37,0,6.48-0.61,9.35-1.83c2.87-1.22,5.26-3.02,7.18-5.39l2.87,3.27c-2.24,2.71-5.07,4.79-8.46,6.24
			c-3.4,1.45-7.08,2.18-11.04,2.18c-5.15,0-9.73-1.12-13.76-3.37c-4.03-2.24-7.18-5.35-9.45-9.3c-2.28-3.96-3.42-8.48-3.42-13.56
			c0-5.01,1.07-9.52,3.22-13.51c2.14-3.99,5.1-7.11,8.86-9.35c3.76-2.24,7.98-3.37,12.67-3.37c4.68,0,8.89,1.11,12.62,3.32
			c3.73,2.21,6.66,5.3,8.81,9.25c2.14,3.96,3.22,8.45,3.22,13.46L1342.31,200.08z M1307.96,179.44c-2.9,1.62-5.23,3.89-6.98,6.83
			c-1.75,2.94-2.76,6.25-3.02,9.95h39.59c-0.2-3.7-1.19-6.99-2.97-9.9c-1.78-2.9-4.12-5.18-7.03-6.83c-2.9-1.65-6.17-2.47-9.8-2.47
			C1314.13,177.02,1310.86,177.83,1307.96,179.44z"
          />
        </g>
        <g>
          <path
            d="M583.35,377.91h-59.2l-11.67,27.4h-22.67l53.28-118.41h21.65l53.45,118.41h-23L583.35,377.91z M576.07,360.65
			l-22.33-51.76l-22.16,51.76H576.07z"
          />
          <path
            d="M704.88,319.72c6.94,3.84,12.37,9.25,16.32,16.24c3.95,6.99,5.92,15.06,5.92,24.19c0,9.13-1.97,17.23-5.92,24.27
			c-3.95,7.05-9.39,12.49-16.32,16.32c-6.94,3.83-14.8,5.75-23.6,5.75c-12.18,0-21.82-4.06-28.93-12.18v43.81h-21.14V314.98h20.13
			v11.84c3.49-4.28,7.81-7.5,12.94-9.64c5.13-2.14,10.8-3.21,17-3.21C690.08,313.96,697.95,315.88,704.88,319.72z M698.12,380.61
			c5.02-5.19,7.53-12.01,7.53-20.47c0-8.46-2.51-15.28-7.53-20.47c-5.02-5.19-11.42-7.78-19.2-7.78c-5.07,0-9.64,1.16-13.7,3.47
			c-4.06,2.31-7.27,5.61-9.64,9.9c-2.37,4.29-3.55,9.25-3.55,14.89c0,5.64,1.18,10.6,3.55,14.88c2.37,4.29,5.58,7.59,9.64,9.9
			c4.06,2.31,8.63,3.47,13.7,3.47C686.7,388.39,693.1,385.8,698.12,380.61z"
          />
          <path
            d="M820.92,319.72c6.94,3.84,12.37,9.25,16.32,16.24c3.95,6.99,5.92,15.06,5.92,24.19c0,9.13-1.97,17.23-5.92,24.27
			c-3.95,7.05-9.39,12.49-16.32,16.32c-6.94,3.83-14.8,5.75-23.6,5.75c-12.18,0-21.82-4.06-28.93-12.18v43.81h-21.14V314.98h20.13
			v11.84c3.49-4.28,7.81-7.5,12.94-9.64c5.13-2.14,10.8-3.21,17-3.21C806.12,313.96,813.99,315.88,820.92,319.72z M814.16,380.61
			c5.02-5.19,7.53-12.01,7.53-20.47c0-8.46-2.51-15.28-7.53-20.47c-5.02-5.19-11.42-7.78-19.2-7.78c-5.07,0-9.64,1.16-13.7,3.47
			c-4.06,2.31-7.27,5.61-9.64,9.9c-2.37,4.29-3.55,9.25-3.55,14.89c0,5.64,1.18,10.6,3.55,14.88c2.37,4.29,5.58,7.59,9.64,9.9
			c4.06,2.31,8.63,3.47,13.7,3.47C802.74,388.39,809.14,385.8,814.16,380.61z"
          />
          <path
            d="M922.24,403.11c-8.52-2.59-15.25-6.03-20.21-10.32l7.61-17.08c4.85,3.83,10.8,6.96,17.85,9.39
			c7.05,2.43,14.12,3.64,21.23,3.64c8.8,0,15.36-1.41,19.71-4.23c4.34-2.82,6.51-6.54,6.51-11.16c0-3.38-1.21-6.17-3.64-8.37
			c-2.43-2.2-5.5-3.92-9.22-5.16c-3.72-1.24-8.8-2.65-15.22-4.23c-9.02-2.14-16.32-4.28-21.91-6.43
			c-5.58-2.14-10.38-5.5-14.38-10.06c-4-4.57-6-10.74-6-18.52c0-6.54,1.78-12.49,5.33-17.85c3.55-5.36,8.91-9.61,16.07-12.77
			c7.16-3.16,15.93-4.74,26.3-4.74c7.22,0,14.32,0.9,21.31,2.71c6.99,1.81,13.02,4.4,18.1,7.78l-6.93,17.08
			c-5.19-3.04-10.6-5.35-16.24-6.94c-5.64-1.58-11.11-2.37-16.41-2.37c-8.68,0-15.14,1.47-19.37,4.4
			c-4.23,2.93-6.34,6.82-6.34,11.67c0,3.38,1.21,6.15,3.64,8.29c2.42,2.14,5.5,3.84,9.22,5.07c3.72,1.24,8.8,2.65,15.22,4.23
			c8.8,2.03,16.01,4.14,21.65,6.34c5.64,2.2,10.46,5.56,14.46,10.06c4,4.51,6,10.6,6,18.27c0,6.54-1.78,12.46-5.33,17.76
			c-3.55,5.3-8.94,9.53-16.15,12.69c-7.22,3.16-16.01,4.74-26.39,4.74C939.58,407,930.75,405.7,922.24,403.11z"
          />
          <path
            d="M1070.67,400.4c-2.48,2.03-5.5,3.55-9.05,4.57c-3.55,1.01-7.3,1.52-11.25,1.52c-9.92,0-17.59-2.59-23-7.78
			c-5.41-5.19-8.12-12.74-8.12-22.67v-43.47h-14.89v-16.92h14.89v-20.64h21.14v20.64h24.19v16.92h-24.19v42.96
			c0,4.4,1.07,7.75,3.21,10.06c2.14,2.31,5.24,3.47,9.3,3.47c4.74,0,8.68-1.24,11.84-3.72L1070.67,400.4z"
          />
          <path
            d="M1100.95,400.57c-7.33-3.95-13.05-9.44-17.17-16.49c-4.12-7.05-6.17-15.03-6.17-23.94c0-8.91,2.06-16.86,6.17-23.85
			c4.12-6.99,9.84-12.46,17.17-16.41c7.33-3.95,15.56-5.92,24.7-5.92c9.25,0,17.53,1.97,24.87,5.92
			c7.33,3.95,13.05,9.42,17.17,16.41c4.12,6.99,6.17,14.94,6.17,23.85c0,8.91-2.06,16.89-6.17,23.94
			c-4.12,7.05-9.84,12.55-17.17,16.49c-7.33,3.95-15.62,5.92-24.87,5.92C1116.51,406.49,1108.28,404.52,1100.95,400.57z
			 M1144.93,380.61c5.07-5.19,7.61-12.01,7.61-20.47c0-8.46-2.54-15.28-7.61-20.47c-5.07-5.19-11.5-7.78-19.28-7.78
			c-7.78,0-14.18,2.6-19.2,7.78c-5.02,5.19-7.53,12.01-7.53,20.47c0,8.46,2.51,15.28,7.53,20.47c5.02,5.19,11.42,7.78,19.2,7.78
			C1133.43,388.39,1139.86,385.8,1144.93,380.61z"
          />
          <path
            d="M1246.42,313.96v20.13c-1.81-0.34-3.44-0.51-4.91-0.51c-8.23,0-14.66,2.4-19.28,7.19c-4.63,4.79-6.94,11.7-6.94,20.72
			v43.81h-21.14v-90.33h20.13v13.19C1220.37,318.7,1231.09,313.96,1246.42,313.96z"
          />
          <path
            d="M1348.08,366.91h-70.88c1.24,6.66,4.48,11.93,9.73,15.82c5.24,3.89,11.76,5.84,19.54,5.84c9.92,0,18.1-3.27,24.53-9.81
			l11.33,13.02c-4.06,4.85-9.19,8.52-15.39,11c-6.2,2.48-13.19,3.72-20.97,3.72c-9.92,0-18.66-1.97-26.22-5.92
			c-7.56-3.95-13.39-9.44-17.51-16.49c-4.12-7.05-6.17-15.03-6.17-23.94c0-8.8,2-16.72,6-23.77c4-7.05,9.56-12.54,16.66-16.49
			c7.1-3.95,15.11-5.92,24.02-5.92c8.8,0,16.66,1.95,23.6,5.84c6.94,3.89,12.35,9.36,16.24,16.41c3.89,7.05,5.84,15.2,5.84,24.44
			C1348.42,362.12,1348.31,364.2,1348.08,366.91z M1285.58,336.89c-4.68,4-7.53,9.33-8.54,15.99h51.25
			c-0.9-6.54-3.67-11.84-8.29-15.9c-4.63-4.06-10.38-6.09-17.25-6.09C1295.99,330.88,1290.26,332.88,1285.58,336.89z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M-823.36,550.71h-1345.28c-31.13,0-56.36-25.23-56.36-56.36V163.67c0-31.12,25.23-56.36,56.36-56.36h1345.28
				c31.12,0,56.36,25.23,56.36,56.36v330.67C-767,525.48-792.23,550.71-823.36,550.71z"
            />
            <path
              d="M-823.36,554.71h-1345.28c-33.28,0-60.36-27.08-60.36-60.36V163.67c0-33.28,27.08-60.36,60.36-60.36h1345.28
				c33.28,0,60.36,27.08,60.36,60.36v330.67C-763,527.63-790.08,554.71-823.36,554.71z M-2168.64,111.32
				c-28.87,0-52.36,23.49-52.36,52.36v330.67c0,28.87,23.49,52.36,52.36,52.36h1345.28c28.87,0,52.36-23.49,52.36-52.36V163.67
				c0-28.87-23.49-52.36-52.36-52.36H-2168.64z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              d="M-1771.59,234.25h-40.19l-8.81,19.5h-5.44l31.87-69.29h5.05l31.87,69.29h-5.44L-1771.59,234.25z M-1773.47,230
				l-18.21-39.99l-18.11,39.99H-1773.47z"
            />
          </g>
          <g>
            <path d="M-1707.34,202.09l-23.36,51.67h-5.05l-23.26-51.67h5.25l20.59,46.23l20.79-46.23H-1707.34z" />
          </g>
          <g>
            <path
              d="M-1665.97,206.59c3.43,3.27,5.15,8.07,5.15,14.4v32.76h-4.75v-9.21c-1.65,3.04-4.06,5.39-7.23,7.08
				c-3.17,1.68-6.96,2.52-11.38,2.52c-5.74,0-10.28-1.35-13.61-4.06c-3.33-2.71-5-6.27-5-10.69c0-4.29,1.53-7.77,4.6-10.44
				c3.07-2.67,7.97-4.01,14.7-4.01h17.72v-4.16c0-4.82-1.3-8.48-3.91-10.99c-2.61-2.51-6.42-3.76-11.43-3.76
				c-3.43,0-6.72,0.59-9.85,1.78c-3.13,1.19-5.79,2.77-7.97,4.75l-2.47-3.56c2.57-2.31,5.67-4.11,9.3-5.39
				c3.63-1.29,7.42-1.93,11.38-1.93C-1674.32,201.69-1669.4,203.32-1665.97,206.59z M-1672.55,247.12c3-1.98,5.26-4.85,6.78-8.61
				v-9.7h-17.62c-5.08,0-8.76,0.93-11.04,2.77c-2.28,1.85-3.41,4.39-3.41,7.62c0,3.37,1.25,6.02,3.76,7.97
				c2.51,1.95,6.04,2.92,10.59,2.92C-1679.2,250.09-1675.55,249.1-1672.55,247.12z"
            />
          </g>
          <g>
            <path
              d="M-1640.83,188.38c-0.79-0.76-1.19-1.7-1.19-2.82c0-1.12,0.4-2.08,1.19-2.87c0.79-0.79,1.75-1.19,2.87-1.19
				c1.12,0,2.08,0.38,2.87,1.14c0.79,0.76,1.19,1.7,1.19,2.82c0,1.12-0.4,2.08-1.19,2.87c-0.79,0.79-1.75,1.19-2.87,1.19
				C-1639.08,189.51-1640.04,189.14-1640.83,188.38z M-1640.43,202.09h4.95v51.67h-4.95V202.09z"
            />
          </g>
          <g>
            <path d="M-1614.6,180.31h4.95v73.45h-4.95V180.31z" />
          </g>
          <g>
            <path
              d="M-1556.59,206.59c3.43,3.27,5.15,8.07,5.15,14.4v32.76h-4.75v-9.21c-1.65,3.04-4.06,5.39-7.23,7.08
				c-3.17,1.68-6.96,2.52-11.38,2.52c-5.74,0-10.28-1.35-13.61-4.06c-3.33-2.71-5-6.27-5-10.69c0-4.29,1.53-7.77,4.6-10.44
				c3.07-2.67,7.97-4.01,14.7-4.01h17.72v-4.16c0-4.82-1.3-8.48-3.91-10.99c-2.61-2.51-6.42-3.76-11.43-3.76
				c-3.43,0-6.72,0.59-9.85,1.78c-3.13,1.19-5.79,2.77-7.97,4.75l-2.47-3.56c2.57-2.31,5.67-4.11,9.3-5.39
				c3.63-1.29,7.42-1.93,11.38-1.93C-1564.94,201.69-1560.03,203.32-1556.59,206.59z M-1563.18,247.12c3-1.98,5.26-4.85,6.78-8.61
				v-9.7h-17.62c-5.08,0-8.76,0.93-11.04,2.77c-2.28,1.85-3.42,4.39-3.42,7.62c0,3.37,1.25,6.02,3.76,7.97
				c2.51,1.95,6.04,2.92,10.59,2.92C-1569.83,250.09-1566.18,249.1-1563.18,247.12z"
            />
          </g>
          <g>
            <path
              d="M-1492.06,205.01c3.89,2.21,6.94,5.31,9.16,9.3c2.21,3.99,3.32,8.53,3.32,13.61c0,5.08-1.11,9.6-3.32,13.56
				c-2.21,3.96-5.26,7.06-9.16,9.3c-3.89,2.24-8.25,3.37-13.07,3.37c-4.62,0-8.79-1.1-12.52-3.32c-3.73-2.21-6.62-5.3-8.66-9.25
				v12.18h-4.75v-73.45h4.95v33.56c2.04-3.83,4.92-6.81,8.61-8.96c3.69-2.14,7.82-3.22,12.37-3.22
				C-1500.31,201.69-1495.95,202.79-1492.06,205.01z M-1494.73,246.92c3.17-1.85,5.66-4.42,7.47-7.72c1.81-3.3,2.72-7.06,2.72-11.28
				c0-4.22-0.91-7.98-2.72-11.28c-1.82-3.3-4.31-5.87-7.47-7.72c-3.17-1.85-6.73-2.77-10.69-2.77c-3.96,0-7.51,0.92-10.64,2.77
				c-3.14,1.85-5.61,4.42-7.42,7.72c-1.82,3.3-2.72,7.06-2.72,11.28c0,4.22,0.91,7.99,2.72,11.28c1.81,3.3,4.29,5.87,7.42,7.72
				c3.13,1.85,6.68,2.77,10.64,2.77C-1501.46,249.7-1497.9,248.77-1494.73,246.92z"
            />
          </g>
          <g>
            <path d="M-1464.24,180.31h4.95v73.45h-4.95V180.31z" />
          </g>
          <g>
            <path
              d="M-1394.76,229.11h-44.44c0.13,4.03,1.15,7.61,3.07,10.74c1.91,3.13,4.5,5.56,7.77,7.28c3.27,1.72,6.94,2.57,11.04,2.57
				c3.37,0,6.48-0.61,9.35-1.83c2.87-1.22,5.26-3.02,7.18-5.39l2.87,3.27c-2.24,2.71-5.07,4.79-8.46,6.24
				c-3.4,1.45-7.08,2.18-11.04,2.18c-5.15,0-9.73-1.12-13.76-3.37c-4.03-2.24-7.18-5.35-9.45-9.3c-2.28-3.96-3.42-8.48-3.42-13.56
				c0-5.01,1.07-9.52,3.22-13.51c2.14-3.99,5.1-7.11,8.86-9.35c3.76-2.24,7.98-3.37,12.67-3.37c4.68,0,8.89,1.11,12.62,3.32
				c3.73,2.21,6.66,5.3,8.81,9.26c2.14,3.96,3.22,8.45,3.22,13.46L-1394.76,229.11z M-1429.11,208.47c-2.9,1.62-5.23,3.89-6.98,6.83
				c-1.75,2.94-2.76,6.25-3.02,9.95h39.59c-0.2-3.69-1.19-6.99-2.97-9.9c-1.78-2.9-4.12-5.18-7.03-6.83
				c-2.9-1.65-6.17-2.47-9.8-2.47C-1422.94,206.04-1426.2,206.85-1429.11,208.47z"
            />
          </g>
          <g>
            <path
              d="M-1346.9,250.79c-3.93-2.24-7.01-5.36-9.25-9.35c-2.24-3.99-3.37-8.5-3.37-13.51c0-5.01,1.12-9.52,3.37-13.51
				c2.24-3.99,5.33-7.11,9.25-9.35c3.93-2.24,8.33-3.37,13.21-3.37c4.88,0,9.29,1.12,13.21,3.37c3.93,2.24,7.01,5.36,9.26,9.35
				c2.24,3.99,3.36,8.5,3.36,13.51c0,5.02-1.12,9.52-3.36,13.51c-2.24,3.99-5.33,7.11-9.26,9.35c-3.93,2.24-8.33,3.37-13.21,3.37
				C-1338.57,254.15-1342.98,253.03-1346.9,250.79z M-1323.05,246.92c3.13-1.85,5.61-4.42,7.42-7.72c1.81-3.3,2.72-7.06,2.72-11.28
				c0-4.22-0.91-7.98-2.72-11.28c-1.82-3.3-4.29-5.87-7.42-7.72c-3.14-1.85-6.68-2.77-10.64-2.77c-3.96,0-7.51,0.92-10.64,2.77
				c-3.13,1.85-5.61,4.42-7.42,7.72c-1.82,3.3-2.72,7.06-2.72,11.28c0,4.22,0.91,7.99,2.72,11.28c1.81,3.3,4.29,5.87,7.42,7.72
				c3.13,1.85,6.68,2.77,10.64,2.77C-1329.73,249.7-1326.18,248.77-1323.05,246.92z"
            />
          </g>
          <g>
            <path
              d="M-1251.93,207.33c3.76,3.76,5.64,9.17,5.64,16.23v30.19h-4.95v-29.79c0-5.81-1.47-10.23-4.4-13.26
				c-2.94-3.03-7.04-4.55-12.32-4.55c-6.07,0-10.86,1.82-14.35,5.44c-3.5,3.63-5.25,8.51-5.25,14.65v27.52h-4.95v-51.67h4.75v11.18
				c1.78-3.63,4.44-6.47,7.97-8.51c3.53-2.04,7.7-3.07,12.52-3.07C-1260.81,201.69-1255.69,203.57-1251.93,207.33z"
            />
          </g>
          <g>
            <path
              d="M-1174.82,250.49c-1.25,1.19-2.82,2.1-4.7,2.72c-1.88,0.63-3.84,0.94-5.89,0.94c-4.49,0-7.95-1.24-10.39-3.71
				c-2.44-2.47-3.66-5.92-3.66-10.34v-33.75h-9.5v-4.26h9.5V190.8h4.95v11.28h16.43v4.26h-16.43v33.26c0,3.37,0.81,5.92,2.42,7.67
				c1.62,1.75,4.01,2.62,7.18,2.62c3.23,0,5.91-0.96,8.02-2.87L-1174.82,250.49z"
            />
          </g>
          <g>
            <path
              d="M-1120.48,207.33c3.76,3.76,5.64,9.17,5.64,16.23v30.19h-4.95v-29.79c0-5.81-1.47-10.23-4.4-13.26
				c-2.94-3.03-7.04-4.55-12.32-4.55c-6.07,0-10.86,1.82-14.35,5.44c-3.5,3.63-5.25,8.51-5.25,14.65v27.52h-4.95v-73.45h4.95v32.57
				c1.78-3.56,4.42-6.32,7.92-8.26c3.5-1.95,7.62-2.92,12.37-2.92C-1129.36,201.69-1124.24,203.57-1120.48,207.33z"
            />
          </g>
          <g>
            <path
              d="M-1050.7,229.11h-44.44c0.13,4.03,1.15,7.61,3.07,10.74c1.91,3.13,4.5,5.56,7.77,7.28c3.27,1.72,6.94,2.57,11.04,2.57
				c3.37,0,6.48-0.61,9.35-1.83c2.87-1.22,5.26-3.02,7.18-5.39l2.87,3.27c-2.24,2.71-5.07,4.79-8.46,6.24
				c-3.4,1.45-7.08,2.18-11.04,2.18c-5.15,0-9.73-1.12-13.76-3.37c-4.03-2.24-7.18-5.35-9.45-9.3c-2.28-3.96-3.42-8.48-3.42-13.56
				c0-5.01,1.07-9.52,3.22-13.51c2.14-3.99,5.1-7.11,8.86-9.35c3.76-2.24,7.98-3.37,12.67-3.37c4.68,0,8.89,1.11,12.62,3.32
				c3.73,2.21,6.66,5.3,8.81,9.26c2.14,3.96,3.22,8.45,3.22,13.46L-1050.7,229.11z M-1085.05,208.47c-2.9,1.62-5.23,3.89-6.98,6.83
				c-1.75,2.94-2.76,6.25-3.02,9.95h39.59c-0.2-3.69-1.19-6.99-2.97-9.9c-1.78-2.9-4.12-5.18-7.03-6.83
				c-2.9-1.65-6.17-2.47-9.8-2.47C-1078.88,206.04-1082.14,206.85-1085.05,208.47z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M-1747.18,375.15h20.33v46.12c-5.95,4.74-12.89,8.38-20.83,10.91c-7.93,2.53-16.09,3.8-24.47,3.8
				c-11.79,0-22.43-2.56-31.91-7.69c-9.48-5.12-16.92-12.2-22.32-21.24c-5.4-9.04-8.1-19.23-8.1-30.58c0-11.35,2.7-21.54,8.1-30.58
				c5.4-9.04,12.87-16.12,22.4-21.24c9.53-5.12,20.25-7.69,32.15-7.69c9.7,0,18.52,1.6,26.45,4.79c7.93,3.2,14.6,7.88,20,14.05
				l-13.56,13.23c-8.82-8.82-19.45-13.23-31.91-13.23c-8.16,0-15.4,1.71-21.74,5.12c-6.34,3.42-11.3,8.21-14.88,14.38
				c-3.58,6.17-5.37,13.22-5.37,21.16c0,7.72,1.79,14.66,5.37,20.83c3.58,6.17,8.54,11.02,14.88,14.55
				c6.34,3.53,13.53,5.29,21.57,5.29c9.04,0,16.97-1.98,23.8-5.95V375.15z"
            />
          </g>
          <g>
            <path
              d="M-1685.02,429.7c-7.17-3.86-12.76-9.23-16.78-16.12c-4.02-6.89-6.03-14.68-6.03-23.39c0-8.7,2.01-16.47,6.03-23.31
				c4.02-6.83,9.61-12.18,16.78-16.04c7.16-3.86,15.21-5.79,24.14-5.79c9.04,0,17.14,1.93,24.3,5.79
				c7.16,3.86,12.75,9.2,16.78,16.04c4.02,6.83,6.03,14.6,6.03,23.31c0,8.71-2.01,16.51-6.03,23.39
				c-4.02,6.89-9.62,12.26-16.78,16.12c-7.17,3.86-15.27,5.79-24.3,5.79C-1669.81,435.49-1677.86,433.56-1685.02,429.7z
				 M-1642.04,410.2c4.96-5.07,7.44-11.74,7.44-20c0-8.27-2.48-14.93-7.44-20c-4.96-5.07-11.24-7.6-18.85-7.6
				c-7.6,0-13.86,2.54-18.76,7.6c-4.91,5.07-7.36,11.74-7.36,20c0,8.27,2.45,14.93,7.36,20c4.9,5.07,11.16,7.6,18.76,7.6
				C-1653.28,417.8-1647,415.27-1642.04,410.2z"
            />
          </g>
          <g>
            <path
              d="M-1578.4,429.7c-7.17-3.86-12.76-9.23-16.78-16.12c-4.02-6.89-6.03-14.68-6.03-23.39c0-8.7,2.01-16.47,6.03-23.31
				c4.02-6.83,9.61-12.18,16.78-16.04c7.16-3.86,15.21-5.79,24.14-5.79c9.04,0,17.14,1.93,24.3,5.79
				c7.16,3.86,12.75,9.2,16.78,16.04c4.02,6.83,6.03,14.6,6.03,23.31c0,8.71-2.01,16.51-6.03,23.39
				c-4.02,6.89-9.62,12.26-16.78,16.12c-7.17,3.86-15.27,5.79-24.3,5.79C-1563.19,435.49-1571.23,433.56-1578.4,429.7z
				 M-1535.42,410.2c4.96-5.07,7.44-11.74,7.44-20c0-8.27-2.48-14.93-7.44-20c-4.96-5.07-11.24-7.6-18.85-7.6
				c-7.6,0-13.86,2.54-18.76,7.6c-4.91,5.07-7.36,11.74-7.36,20c0,8.27,2.45,14.93,7.36,20c4.9,5.07,11.16,7.6,18.76,7.6
				C-1546.66,417.8-1540.38,415.27-1535.42,410.2z"
            />
          </g>
          <g>
            <path
              d="M-1399.7,346.06v74.89c0,31.08-15.87,46.62-47.61,46.62c-8.49,0-16.53-1.07-24.14-3.22c-7.6-2.15-13.89-5.26-18.85-9.34
				l9.26-15.54c3.86,3.2,8.73,5.76,14.63,7.69c5.89,1.93,11.87,2.89,17.94,2.89c9.7,0,16.8-2.21,21.32-6.61
				c4.52-4.41,6.78-11.13,6.78-20.17v-4.63c-3.53,3.86-7.83,6.78-12.89,8.76c-5.07,1.98-10.64,2.98-16.7,2.98
				c-8.38,0-15.95-1.79-22.73-5.37c-6.78-3.58-12.12-8.62-16.03-15.13c-3.91-6.5-5.87-13.94-5.87-22.32
				c0-8.37,1.96-15.79,5.87-22.23c3.91-6.45,9.26-11.43,16.03-14.96c6.78-3.53,14.35-5.29,22.73-5.29c6.39,0,12.2,1.05,17.44,3.14
				c5.23,2.1,9.61,5.29,13.14,9.59v-11.74H-1399.7z M-1427.55,405.82c5.01-4.68,7.52-10.77,7.52-18.27c0-7.38-2.51-13.39-7.52-18.02
				c-5.02-4.63-11.44-6.94-19.26-6.94c-7.93,0-14.41,2.31-19.42,6.94c-5.02,4.63-7.52,10.64-7.52,18.02c0,7.5,2.51,13.58,7.52,18.27
				c5.01,4.69,11.49,7.03,19.42,7.03C-1438.99,412.84-1432.57,410.5-1427.55,405.82z"
            />
          </g>
          <g>
            <path d="M-1372.59,311.67h20.66v122.66h-20.66V311.67z" />
          </g>
          <g>
            <path
              d="M-1242.16,396.81h-69.27c1.21,6.5,4.38,11.65,9.51,15.46c5.12,3.8,11.49,5.7,19.09,5.7c9.7,0,17.69-3.2,23.97-9.59
				l11.08,12.73c-3.97,4.74-8.98,8.32-15.04,10.75c-6.06,2.43-12.89,3.64-20.5,3.64c-9.7,0-18.24-1.93-25.62-5.79
				c-7.38-3.86-13.09-9.23-17.11-16.12c-4.02-6.89-6.03-14.68-6.03-23.39c0-8.6,1.96-16.34,5.87-23.23
				c3.91-6.89,9.34-12.26,16.28-16.12c6.94-3.86,14.77-5.79,23.47-5.79c8.6,0,16.28,1.9,23.06,5.7c6.78,3.8,12.07,9.15,15.87,16.04
				c3.8,6.89,5.7,14.85,5.7,23.89C-1241.83,392.12-1241.94,394.16-1242.16,396.81z M-1303.24,367.46
				c-4.57,3.91-7.36,9.12-8.35,15.62h50.09c-0.88-6.39-3.58-11.57-8.1-15.54c-4.52-3.97-10.14-5.95-16.86-5.95
				C-1293.08,361.6-1298.67,363.55-1303.24,367.46z"
            />
          </g>
          <g>
            <path
              d="M-1100.57,323.57c7.44,3.31,13.14,8.05,17.11,14.22c3.97,6.17,5.95,13.5,5.95,21.99c0,8.38-1.98,15.68-5.95,21.9
				c-3.97,6.23-9.67,10.99-17.11,14.3c-7.44,3.31-16.18,4.96-26.2,4.96h-26.12v33.39h-21.49V318.61h47.61
				C-1116.75,318.61-1108.01,320.27-1100.57,323.57z M-1106.44,376.8c4.85-3.97,7.27-9.64,7.27-17.03c0-7.38-2.43-13.06-7.27-17.03
				c-4.85-3.97-11.96-5.95-21.32-5.95h-25.13v45.96h25.13C-1118.4,382.76-1111.29,380.77-1106.44,376.8z"
            />
          </g>
          <g>
            <path d="M-1056.35,311.67h20.66v122.66h-20.66V311.67z" />
          </g>
          <g>
            <path
              d="M-945.02,354.4c6.89,6.23,10.33,15.62,10.33,28.19v51.74h-19.51v-10.75c-2.54,3.86-6.15,6.81-10.83,8.84
				c-4.69,2.04-10.33,3.06-16.94,3.06c-6.61,0-12.4-1.13-17.36-3.39c-4.96-2.26-8.79-5.4-11.49-9.42c-2.7-4.02-4.05-8.57-4.05-13.64
				c0-7.93,2.95-14.3,8.84-19.09c5.89-4.79,15.18-7.19,27.85-7.19h22.81v-1.32c0-6.17-1.85-10.91-5.54-14.22
				c-3.69-3.31-9.17-4.96-16.45-4.96c-4.96,0-9.84,0.77-14.63,2.31c-4.79,1.54-8.84,3.69-12.15,6.45l-8.1-15.04
				c4.63-3.53,10.19-6.23,16.7-8.1c6.5-1.87,13.39-2.81,20.66-2.81C-961.85,345.06-951.91,348.18-945.02,354.4z M-963.86,416.89
				c4.02-2.37,6.86-5.76,8.51-10.17v-10.25h-21.33c-11.9,0-17.85,3.91-17.85,11.74c0,3.75,1.49,6.72,4.46,8.93
				c2.98,2.21,7.11,3.31,12.4,3.31C-972.49,420.45-967.89,419.26-963.86,416.89z"
            />
          </g>
          <g>
            <path
              d="M-827.23,346.06l-41.33,95.39c-3.86,9.59-8.54,16.34-14.05,20.25c-5.51,3.91-12.18,5.87-20,5.87
				c-4.41,0-8.76-0.72-13.06-2.15c-4.3-1.43-7.82-3.42-10.58-5.95l8.27-15.21c1.98,1.87,4.32,3.36,7.03,4.46
				c2.7,1.1,5.43,1.65,8.18,1.65c3.64,0,6.64-0.94,9.01-2.81c2.37-1.88,4.55-5.02,6.53-9.42l1.49-3.47l-38.52-88.61h21.49
				l27.77,65.3l27.94-65.3H-827.23z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              d="M-1874.17,323.35l-55.55-32.06l-45.69,44.16l41.75,40.36l59.48-34.33c3.27-1.89,5.23-5.28,5.23-9.06
				C-1868.94,328.64-1870.9,325.25-1874.17,323.35z"
            />
          </g>
        </g>
        <g>
          <g>
            <polygon points="-1939.15,285.85 -2000.28,250.57 -2134.25,181.91 -1982.94,328.18 			" />
          </g>
        </g>
        <g>
          <g>
            <polygon points="-2132.02,486.85 -2000.05,414.15 -1943.09,381.27 -1982.94,342.74 			" />
          </g>
        </g>
        <g>
          <g>
            <polygon points="-2143.19,187.83 -2143.33,483.23 -1990.47,335.46 			" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WhiteAppleStoreIcon;
