import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";

import sample from "../../assets/img/trees.jpeg";

const BlogCard = ({ img, title, date, id }) => {
  const navigate = useNavigate();

  const redirectToBlogDetailPage = (id, title) => {
    return navigate(`/blog/${id}/${title.toLowerCase().split(" ").join("-")}`);
  };

  return (
    <div
      className="blog-card"
      onClick={() => redirectToBlogDetailPage(id, title)}
    >
      <div className="blog-card-img">
        <img src={img || sample} alt="" />
      </div>
      <h3 className="blog-card-title">
        {title || "Lorem ipsum dolor sit amet consectetur."}
      </h3>
      <span className="blog-card-date">
        <Moment format="MMM Do YYYY">{date}</Moment>
      </span>
      <Link to={`/blog/${id}/${title.toLowerCase().split(" ").join("-")}`}>
        read
        <FontAwesomeIcon icon={faAngleRight} />
      </Link>
    </div>
  );
};

export default BlogCard;

// PROPTYPES
BlogCard.propTypes = {
  id: PropTypes.number,
  img: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
};
