import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import AllComp from "./Components/AllComp";
import Blog from "./pages/Blog/Blog";
// import Projects from "./Components/Projects/Projects";
import Navbar from "./Components/HeaderSection/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import "./App.css";
import BlogDetail from "./pages/BlogDetail/BlogDetails";

// initialize client
// const client = new ApolloClient({
//   uri: process.env.REACT_APP_GRAPHQL_URL,
//   cache: new InMemoryCache(),
// });

function App() {
  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<AllComp />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id/:title" element={<BlogDetail />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
