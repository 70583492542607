import WhiteStoreDownloadBtns from "../Shared/WhiteStoreDownloadBtns/WhiteStoreDownloadBtns";
import "./style.css";

// import appStore from "../../assets/img/Headersection/newappStore.svg";
// import googlePlay from "../../assets/img/Headersection/newGooglePlay.svg";

const DownLoadApp = () => {
  return (
    <div className="down-load-app">
      <h2>Be the reason our planet is preserved for many generations</h2>
      <WhiteStoreDownloadBtns />
    </div>
  );
};

export default DownLoadApp;
