// components
import SectionOne from "./SectionOne/SectionOne";
import SectionTwo from "./SectionTwo/SectionTwo";

const Sections = () => {
  return (
    <div className="section">
      <SectionOne />
      <SectionTwo />
    </div>
  );
};

export default Sections;
