import { Helmet } from "react-helmet-async";
import HeroComp from "./HeroComp/HeroComp";
import Sections from "./Sections/Sections";
import DownLoadApp from "./DownLoadApp/DownLoadApp";
import About from "./About/About";
import FQAs from "./FQAs/FQAs";
import Subscribe from "./Subscribe/Subscribe";

const AllComp = () => {
  return (
    <>
      <Helmet>
        <title>Eden Zero | Welcome</title>
      </Helmet>
      <div className="all-components">
        <HeroComp />
        <Sections />
        <DownLoadApp />
        <About />
        <FQAs />
        <Subscribe />
      </div>
    </>
  );
};

export default AllComp;
