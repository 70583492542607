import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { data } from "../../data/faq";

import "./style.css";

// import Accordian from "./Accordian/Accordian";

const FQAs = () => {
  const [clicked, setClicked] = useState(false);
  const location = useLocation();

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }

    setClicked(index);
  };

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="fqas-wrap" id="faq-section">
      <div className="fqas">
        <h1>FAQs</h1>
        <div className="accordian-wrap">
          {data.map((item, index) => {
            return (
              <div className="accordian" key={index}>
                <div
                  className="accordian-heading"
                  onClick={() => toggle(index)}
                >
                  <p>{item.question}</p>
                  <span>{clicked === index ? "-" : "+"}</span>
                </div>
                {clicked === index ? (
                  <div className="accordian-content">
                    <p>
                      {item.answer === undefined
                        ? "answer not available"
                        : parse(item.answer)}
                    </p>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FQAs;
