import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";

import FootPrintImg from "../../assets/img/carbon-footprint.png";

const About = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div id="about-section" className="about-wrap">
      <div className="about">
        <div className="about-img">
          <img src={FootPrintImg} alt="" />
        </div>
        <div className="about-content">
          <h1>About Eden Zero</h1>
          <p>
            Eden Zero is a team of changemakers dedicated to making our planet
            safer one person at a time through a conscious mindshift to fight
            against climate change.
          </p>
          <p>
            Our platform is designed to inform, guide and help you take action
            in different aspects of your Daily life for a more positive impact
            for the planet. So, join millions in the fight against global
            warming!
          </p>
          <p>
            We are building a culture where everyone can get involved in
            safeguarding our environment.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
