import axios from "../api/axios";

export const fetchPostsService = () => {
  const getPosts = async (id) => {
    try {
      const { data } = await axios.get(`/top-recommendations/${id}?populate=*`);
      // console.log(data);
      return data;
    } catch (error) {
      // console.log(error);
      return false;
    }
  };

  const getSinglePost = async (id) => {
    try {
      const { data } = await axios.get(
        `/child-recommendations/${id}?populate=*`
      );
      return data;
    } catch (error) {
      // console.log(error);
      return false;
    }
  };

  return { getPosts, getSinglePost };
};
