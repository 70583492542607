import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Moment from "react-moment";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { fetchPostsService } from "../../services/fetchPostsService";
import Container from "../../Components/Shared/Container/Container";
import Spinner from "../../Components/Shared/Spinner/Spiner";
import "./styles.css";

// import sample from "../../assets/img/trees.jpeg";
const { getSinglePost } = fetchPostsService();

const BlogDetail = () => {
  const { id } = useParams();
  const [singlePost, setSinglePost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    const fetchSinglePost = async (id) => {
      const response = await getSinglePost(id);

      if (mounted) {
        // console.log(response);
        const { data } = response;
        setSinglePost(data);
        setLoading(false);
      }
    };

    fetchSinglePost(id);

    return () => (mounted = false);
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Eden Zero | Blog</title>
      </Helmet>
      <div className="blog-detail-wrapper">
        <Container>
          {loading && <Spinner />}
          {!loading && singlePost !== null && (
            <>
              <div className="breadcrumb">
                <ul>
                  <li>
                    <Link to="/blog">blog</Link>
                  </li>
                  <li>{singlePost.attributes.name}</li>
                </ul>
              </div>
              <div className="blog-header">
                <p className="blog-date">
                  <Moment format="MMM Do YYYY">
                    {singlePost.attributes.publishedAt}
                  </Moment>
                </p>
                <h1>{singlePost.attributes.name}</h1>
              </div>
              <div className="blog-detail-img">
                <img src={singlePost.attributes.imageUrl} alt="" />
              </div>
              <div className="blog-content">
                {singlePost.attributes.post.data === null ? (
                  "not available yet"
                ) : (
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {singlePost.attributes.post.data.attributes.content}
                  </ReactMarkdown>
                )}
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default BlogDetail;
