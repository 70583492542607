import "./style.css";

const Subscribe = () => {
  return (
    <div className="subscribe-wrap">
      <div className="subscribe">
        <div className="subscribe-content">
          <h1>Lets keep you updated!</h1>
          <p>Signup on our timely updates on the climates and carbon offsets</p>
        </div>
        <div className="subscribe-form">
          <form action="">
            <input type="email" name="email" placeholder="Email Address" />
            <button className="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
