import WhiteAppleStoreIcon from "../SVG/WhiteAppleStoreIcon/WhiteAppleStoreIcon";
import WhiteGooglePlayIcon from "../SVG/WhiteGooglePlayIcon/WhiteGooglePlayIcon";
import "./styles.css";

const WhiteStoreDownloadBtns = () => {
  return (
    <div className="download-btns">
      <a href="/" className="app-store-btn" target="_blank" rel="noreferrer">
        <WhiteAppleStoreIcon />
      </a>

      <a href="/" className="google-play-btn" rel="noreferrer">
        <WhiteGooglePlayIcon />
      </a>
    </div>
  );
};

export default WhiteStoreDownloadBtns;
