export const data = [
  {
    question: "Why are we fighting against global warming?",
    answer:
      "The consequence of global warming is everywhere; increased wildfire, excess rainfall, flooded farmlands and rising sea levels. Climate change puts us at the risk of living less healthier lives, increasing food prices and loss of our beautiful world. Imagine having to pay for Oxygen because the atmosphere is polluted, or not being able to visit your favorite parks, because everywhere is flooded. So, I guess, you should join the fight against global warming.",
  },
  {
    question: "What is carbon emission?",
    answer:
      "Carbon dioxide is released into the atmosphere everytime you cut down a tree to burn, gas released from gasoline cars and the gas flaring you see from activities like cement production. These emissions depletes the ozone layer causing global warming.",
  },
  {
    question: "Why should I calculate my carbon footprint?",
    answer:
      "We all have our roles in global warming throughout individual actions. Your carbon footprint is the total amount of carbon emission generated by your daily activities. By identifying your carbon footprint, you can see how you contribute to the global carbon emisissions so that you can act and adjust fast!",
  },
  {
    question: "How will my individual footprint be calculated?",
    answer:
      "Your carbon footprint is calculated based on answers that you provide to specific lifestyle questions, measured with the national baseline of your country of residence. This gets us a close estimate based on your individual circumstances and personal choices. You can also calculate your carbon footprint anytime!",
  },
  {
    question: "Will Carbon offsetting impact climate change?",
    answer:
      "Yes, Carbon offsetting impacts climate change positively, because you get the opportunity to build or fund projects that reduce greenhouse emissions across your country of residence. At the moment you can fund two offset projects; Portable Clean Power Generators and Tree Planting.",
  },
  {
    question: "Can I monitor my carbon offset?",
    answer:
      "This can be done in the app via your individualised dashboard. Click on the track your progress button. Immediately, you get a report of your carbon footprint, sponsored projects and carbon offset. With this information, you can see your individual contribution to climate change.",
  },
  {
    question: "When should I change my offset strategy?",
    answer:
      "This can be done anytime.<br>You can change your offset strategy if you want to increase your commitment to fighting climate change or after you have recalculated your carbon footprint, you ‘ll be asked if you want to change your offset.",
  },
  {
    question: "What happens if I change or lose my device?",
    answer:
      "No worries, if you lose or change devices, your offsets are still active. All you have to do is re-install the EdenZero App, sign in with your existing account details and all your offset will be right there.",
  },
];
