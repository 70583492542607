import axios from "../api/axios";

export const topRecommendationsService = () => {
  const getTopRecommendations = async () => {
    try {
      const { data } = await axios.get("/top-recommendations?populate=*");
      // console.log(data);
      return data;
    } catch (error) {
      return false;
      // console.log(error);
    }
  };

  return { getTopRecommendations };
};
